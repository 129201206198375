import axios from 'axios';
import { decrypt, encrypt } from '../helpers/helpers'
import { message } from 'antd'
require('dotenv').config();

const token = localStorage.getItem('accessToken') ? JSON.parse(decrypt(localStorage.getItem('accessToken'))) : []

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['client-id'] = process.env.REACT_APP_CLIENT_ID;
axios.defaults.headers.common['client-secret'] = process.env.REACT_APP_CLIENT_SECRET;
axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const api = async (method, path, payload) => {
    return axios({
        method: method,
		url: method == 'GET' && payload ? `${path}?` + new URLSearchParams(payload) : `${path}`,
        data: payload
    }).then((response) => {

        // auth login
        if(path == 'auth/login' && response?.data?.meta?.success){
            var data = response?.data?.data
            localStorage.setItem('accessToken', encrypt(JSON.stringify(data?.access_token)));
            localStorage.setItem('currentUser', encrypt(JSON.stringify(data)));
            localStorage.setItem('roleAccess', encrypt(JSON.stringify(data?.role?.role_access)))
        
            message.destroy()
            message.success('Successfully authenticated', 2)
            setTimeout(() => {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectUri = urlParams.get('redirect_to');
                if (redirectUri) {
                    window.location.href = redirectUri
                } else {
                    window.location.href = '/'
                }
            }, 1000);
        }

        // success
        if (response?.data?.meta?.success) return response?.data?.data
        else return response?.data?.meta?.message
    }).catch((error) => {

        // session timeout
        if(error?.response?.status == 401) {
            message.error(error.response?.data?.meta?.message)

            setTimeout(() => {
                localStorage.clear();
                const urlParams = new URLSearchParams(window.location.search);
                const redirectUri = urlParams.get('redirect_to');
                if (redirectUri) {
                    window.location.href = redirectUri
                } else {
                    window.location.href = '/login'
                }
            }, 1000);

        } else {
            message.error(error.response?.data?.meta?.message)
        }
    });
}

export const logout = (redirect = '') => {
    localStorage.clear();
    
    if (redirect) window.location.href = "/login?redirect_to=" + redirect
    else window.location.href = '/login'
}

export const currentUser = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : {}