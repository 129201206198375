import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Input, Row, Select, DatePicker, Button, message } from 'antd'
import { FormModal, PageTitle, Spinner, Uploader } from '../../components'
import { api } from '../../services/api'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

const { Option } = Select

const UpdateTicket = () => {
    const { ticketId } = useParams()
    const history = useHistory()
    const [form] = Form.useForm()
    const [scopes, setScopes] = useState([])
    const [scope, setScope] = useState([])
    const [locations, setLocations] = useState([])
    const [location, setLocation] = useState([])
    const [types, setTypes] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [customer, setCustomer] = useState([])
    const [showFormModal, setShowFormModal] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [typeId, setTypeId] = useState(0)

    const fetchTicket = () => {
        api("GET", `ticket/${ticketId}`).then((res) => {

            const scopes = []
            const scope = []
            res.scopes.forEach((el) => {
                let obj = { value: el.scope_id, label: el?.scope?.name }
                scopes.push(obj)
                scope.push({ id: el.scope_id })
            })

            const locations = []
            const location = []
            res.locations.forEach((el) => {
                let obj = { value: el.location_id, label: el?.location?.name }
                locations.push(obj)
                location.push({ id: el.location_id })
            })

            form.setFieldsValue({
                number: res.number,
                type_id: res.type_id,
                target_completion: res.target_completion ? moment(res.target_completion) : null,
                requested_at: moment(res.requested_at),
                requested_by: res.requested_by,
                description: res.description,
                remark: res.remark,
                scopes: scopes,
                locations: locations
            })

            setScope(scope)
            setLocation(location)
            setLoaded(true)
        })
    }

    const fetchScope = () => {
        api("GET", "scopes?status=ACTIVE").then((res) => {
            let newCat = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                newCat.push(obj)
            });
            setScopes(newCat)
        })
    }

    const fetchLocation = () => {
        api("GET", "locations?status=ACTIVE").then((res) => {
            let newLoc = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                newLoc.push(obj)
            });
            setLocations(newLoc)
        })
    }

    const fetchCustomer = () => {
        api("GET", "customers?status=ACTIVE").then((res) => {
            let customers = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                customers.push(obj)
            });
            setCustomer(customers)
        })
    }

    const fetchType = () => {
        api("GET", "types?status=ACTIVE").then((res) => {
            setTypes(res)
        })
    }

    useEffect(() => {
        fetchTicket()
        fetchScope()
        fetchLocation()
        fetchCustomer()
        fetchType()

    }, []);


    const createCustomer = () => {
        setModalTitle('Add New Customer')
        setShowFormModal(true)
    }

    const saveCustomer = (v) => {
        let payload = {
            name: v.name,
            email: v.email,
            phone: v.phone,
            company: v.company,
            status: "ACTIVE",
        }

        api("POST", "customer", payload).then((res) => {
            message.success('Successfully saved data')
            fetchCustomer()
            setShowFormModal(false)
        })
    }

    const handleLocation = (v) => {
        const loc = []
        v.forEach((el) => {
            let obj = { id: el }
            loc.push(obj)
        })

        setLocation(loc)
    }

    const handleScope = (v) => {
        const scope = []
        v.forEach((el) => {
            let obj = { id: el }
            scope.push(obj)
        })

        setScope(scope)
    }

    const fileUploaded = (v) => {
        let atcs = attachmentFile
        if (v.meta.success) {
            atcs.push({ attachment: v.data.url })
        }
        setAttachmentFile(atcs)
    }

    const onFinish = (v) => {
        let payload = {
            number: v.number,
            type_id: v.type_id,
            target_completion: v.target_completion ? moment(v.target_completion).format('YYYY-MM-DD HH:mm:ss') : null,
            requested_at: moment(v.requested_at).format('YYYY-MM-DD HH:mm:ss'),
            description: v.description,
            remark: v.remark,
            locations: location,
            scopes: scope,
            attachments: attachmentFile,
        }

        api("PUT", `ticket/${ticketId}`, payload).then((res) => {
            setSaving(false)
            message.success('Successfully saved data')
            history.push('/tickets')
        }).catch((err) => {
            setSaving(false)
            message.warning('Failed to saving data')
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Ticket - Edit" breadcrumbs={[['Ticket', '/tickets'], ['Edit']]} />
            <Card title="Ticket">
                {
                    loaded ?
                        <Form onFinish={onFinish} form={form} layout='vertical'>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Scopes" name="scopes" rules={[{ required: true }]}>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Select Scope"
                                            onChange={handleScope}
                                            options={scopes}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="WO Number" name="number" rules={[{ required: true }]}>
                                        <Input placeholder="WO Number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Type" name="type_id" rules={[{ required: true }]}>
                                        <Select placeholder="Select Type" onChange={(v) => setTypeId(v)}>
                                            {
                                                types.map((el, key) => (
                                                    <Option key={key} value={el.id}>{el.name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Remark" name="remark">
                                        <Input placeholder="Remark" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Requested At" name="requested_at" rules={[{ required: true }]}>
                                        <DatePicker showTime style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Baseline End Date" name="target_completion" rules={typeId === 2 || typeId === 3 ? [{ required: true }] : null}>
                                        <DatePicker showTime style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between'>
                                <Col span={11}>
                                    <Form.Item label="Locations" name="locations" rules={[{ required: true }]}>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Select Location"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleLocation}
                                            options={locations}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Row justify="space-between">
                                        <Col span={16}>
                                            <Form.Item label="Requested By" name="requested_by" rules={[{ required: true }]}>
                                                <Select
                                                    showSearch
                                                    placeholder="Select Customer"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={customer}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <div style={{ marginTop: 30 }}>
                                                <Button type='primary' onClick={createCustomer}>
                                                    Add Customer
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                        <Input.TextArea placeholder="Description" rows={7} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Attachment" rules={[{ required: true }]}>
                                        <Uploader multiple={true} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                {
                                    uploading || saving ?
                                        <Button disabled style={{ width: '20%' }} type="primary" htmlType="submit">
                                            <Spinner />
                                        </Button>
                                        :
                                        <Button style={{ width: '20%' }} type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                }
                            </Form.Item>
                        </Form>
                        : null
                }
            </Card>

            {
                (showFormModal) ?
                    <FormModal title={modalTitle} submitForm={(v) => saveCustomer(v)} width={500} showModal={showFormModal} onCloseModal={() => setShowFormModal(false)} saving={saving}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                            <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item label="Phone" name="phone">
                            <Input placeholder="Phone" />
                        </Form.Item>
                        <Form.Item label="Company" name="company">
                            <Input placeholder="Company" />
                        </Form.Item>
                    </FormModal> :
                    null
            }
        </Card>
    )
}

export default UpdateTicket