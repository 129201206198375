import React, { useEffect, useState } from 'react'
import { Card, Space, Tag, Typography, message, Button, Drawer, Row, Col, Form, Select, DatePicker } from 'antd'
import { FilterOutlined } from '@ant-design/icons';
import { PageTitle } from '../../components/PageTitle'
import { api } from '../../services/api'
import { Spinner } from '../../components/Spinner'
import { DataTable } from '../../components/DataTable'
import { AddButton, DeleteButton, EditButton, ShowButton } from '../../components/Button'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { decrypt } from '../../helpers/helpers'
import { roleAccess } from '../../helpers/menus'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker;
const user = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : []

const Ticket = () => {
    const history = useHistory()
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [scopes, setScopes] = useState([])
    const [scopeIds, setScopeIds] = useState([])
    const [locations, setLocations] = useState([])
    const [locationIds, setLocationIds] = useState([])
    const [types, setTypes] = useState([])
    const [priorities, setPriorities] = useState([])
    const [data, setData] = useState([]);
    const [paginationPage, setPaginationPage] = useState(1);

    const fetchTicket = () => {
        api("GET", `tickets`).then((res) => {
            res?.data?.map((item, i) => {
                item.priorities = item.priority ? 
                    item.overdue?.is_overdue ? 'OUT OF TARGET' : 'ON TRACK'
                : '-';
            })
            setTickets(res.data)
            setData(res)
            setLoaded(true)
        })
    }

    const fetchScope = () => {
        api("GET", "scopes?status=ACTIVE").then((res) => {
            let scope = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                scope.push(obj)
            });
            setScopes(scope)
        })
    }

    const fetchType = () => {
        api("GET", "types?status=ACTIVE").then((res) => {
            setTypes(res)
        })
    }

    const fetchPriority = () => {
        api("GET", "priorities?status=ACTIVE").then((res) => {
            setPriorities(res)
        })
    }

    const fetchLocation = () => {
        api("GET", "locations?status=ACTIVE").then((res) => {
            let location = []
            res.forEach(e => {
                let obj = { label: e.name, value: e.id }
                location.push(obj)
            });
            setLocations(location)
        })
    }

    useEffect(() => {
        fetchTicket()
        fetchScope()
        fetchType()
        fetchLocation()
        fetchPriority()

    }, []);

    const handleLocation = (v) => {
        const loc = []
        v.forEach((el) => {
            loc.push(el)
        })

        setLocationIds(loc)
    }

    const handleScope = (v) => {
        const scope = []
        v.forEach((el) => {
            scope.push(el)
        })

        setScopeIds(scope)
    }

    const deleteTicket = (v) => {
        api("DELETE", `ticket/${v}`).then((res) => {
            message.success("Successfully deleted data")
            fetchTicket()
        })
    }

    const columns = [
        {
            title: 'No',
            align: 'center',
            render: (row) => (
                `${(parseFloat((paginationPage-1) * 10) + parseFloat(row.no))}`
            ),
            width: 50,
            fixed: 'left',
        },
        {
            title: 'Reference',
            key: 'reference',
            render: (row) => (
                <Button type='link' onClick={() => history.push(`/ticket/view/${row.id}`)}>
                    {row.reference}
                </Button>
            ),
            fixed: 'left',
            width: 150,
            align: 'center'
        },
        {
            title: 'WO Number',
            key: 'number',
            dataIndex: 'number',
            width: 150,
        },
        {
            title: 'Description',
            key: 'description',
            render: (row) => (
                `${row.description?.substring(0, 50)} ${row.description?.length > 50 ? '..' : ''}`
            ),
            width: 200
        },
        {
            title: 'Scopes',
            key: 'scopes',
            render: (row) => (
                row?.scopes?.map((el) => (
                    <Tag color='blue'>{el.scope?.name}</Tag>
                ))
            ),
            align: 'center',
            width: 150
        },
        {
            title: 'Type',
            key: 'type',
            render: (text) => (
                text.type_id !== null ? text.type?.name : '-'
            ),
            align: 'center',
            width: 100
        },
        {
            title: 'Assign To',
            key: 'assign_to',
            render: (row) => (
                row?.engineers?.map((el) => (
                    <Tag color='cyan'>{el.user?.name}</Tag>
                ))
            ),
            align: 'center',
            width: 250
        },
        {
            title: 'Locations',
            key: 'locations',
            render: (row) => (
                row?.locations?.map((el) => (
                    <Tag color='orange'>{el.location?.name}</Tag>
                ))
            ),
            align: 'center',
            width: 150
        },
        {
            title: 'Requested At',
            key: 'requested_at',
            render: (text) => (
                text.requested_at === null ? '-' : moment(text.requested_at).format('DD MMMM YYYY, HH:mm:ss')
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Baseline End Date',
            key: 'target_completion',
            render: (text) => (
                text.target_completion === null ? '-' : moment(text.target_completion).format('DD MMMM YYYY, HH:mm:ss')
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Closed At',
            key: 'closed_at',
            render: (text) => (
                text.closed_at === null ? '-' : moment(text.closed_at).format('DD MMMM YYYY, HH:mm:ss')
            ),
            align: 'center',
            width: 200
        },
        {
            title: 'Priority',
            render: (row) => (
                <Tag color={row.priorities === 'ON TRACK' ? 'green' : 'red'}>{row.priorities}</Tag>
            ),
            width: 150,
            align: 'center'
        },
        {
            title: 'Assign',
            key: 'status',
            render: (row) => (
                row?.engineers?.length === 0 ?
                    "-"
                    : <Tag color={"green"}>ASSIGNED</Tag>
            ),
            align: 'center',
            fixed: 'right',
            width: 100
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <Tag color={text === "OPEN" ? "red" : text === "CLOSED" ? "green" : "blue"}>{text}</Tag>
            ),
            align: 'center',
            fixed: 'right',
            width: 100
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        {roleAccess('TICKET_VIEW') ?
                            <ShowButton onShow={() => history.push(`/ticket/view/${row.id}`)} />
                            : null}
                        {roleAccess('TICKET_EDIT') && row.status == 'OPEN' ?
                            <EditButton onEdit={() => history.push(`/ticket/edit/${row.id}`)} />
                            : null}
                        {roleAccess('TICKET_DELETE') && row.status == 'OPEN' ?
                            <DeleteButton onConfirm={() => deleteTicket(row.id)} />
                            : null}
                    </Space>
                </div>
            ),
            align: 'center',
            fixed: 'right',
        },
    ];


    const onFinishFilter = (v) => {

        setLoaded(false)
        const start_date = v.requested_at ? moment(v.requested_at[0]).format("YYYY-MM-DD HH:mm:ss") : ''
        const end_date = v.requested_at ? moment(v.requested_at[1]).format("YYYY-MM-DD HH:mm:ss") : ''
        api("GET", `tickets?scope_id=${scopeIds}&location_id=${locationIds}&priority_id=${v.priority_id ? v.priority_id : ''}&type_id=${v.type_id ? v.type_id : ''}&status=${v.status ? v.status : ''}&overdue=${v.overdue ? v.overdue : ''}&start_date=${start_date}&end_date=${end_date}`).then((res) => {
            res?.data?.map((item, i) => {
                item.priorities = item.priority ? 
                    item.overdue?.is_overdue ? 'OUT OF TARGET' : 'ON TRACK'
                : '-';
            })

            setTickets(res.data)
            setData(res)
            setLoaded(true)
            setShowDrawer(false)
            setScopeIds([])
            setLocationIds([])
            form.setFieldsValue({
                locations: []
            })
        })

    }

    const resetFilter = () => {
        fetchTicket()
        setShowDrawer(false)
    }

    const handleChangePagination = (el) => {
        setLoaded(false)
        api("GET", `tickets?page=${el.current}`).then((res) => {
            res?.data?.map((item, i) => {
                item.priorities = item.priority ? 
                    item.overdue?.is_overdue ? 'OUT OF TARGET' : 'ON TRACK'
                : '-';
            })
            setTickets(res.data)
            setLoaded(true)
        })
        setPaginationPage(el.current)
    }


    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Tickets" breadcrumbs={[['Tickets']]} />
                <Card title={
                    <div className="full-width">
                        <Row justify="space-between">
                            <Col>
                                <Text strong style={{ float: 'left' }}>Tickets</Text>
                            </Col>
                            <Col>
                                <Space>
                                    {roleAccess('TICKET_ADD') ?
                                        <AddButton right onAdd={() => history.push('/ticket/create')} title="New Ticket" />
                                        : null}
                                    <Button onClick={() => setShowDrawer(true)} shape="round" icon={<FilterOutlined />} size="middle" />
                                </Space>
                            </Col>
                        </Row>
                    </div>}>
                    {
                        (loaded) ?
                            <DataTable size='small' bordered 
                                dataSource={tickets} 
                                columns={columns} scroll={{ x: 900 }}
                                onChange={handleChangePagination}
                                pagination={{
                                    total: data.total,
                                    current: paginationPage
                                }} />
                            :
                            <Spinner />
                    }
                </Card>

                {
                    showDrawer ?
                        <Drawer
                            title="Search Ticket"
                            placement="right"
                            width={400}
                            onClose={() => setShowDrawer(false)}
                            open={showDrawer}
                        >
                            <Form onFinish={onFinishFilter} form={form} layout='vertical'>
                                <Form.Item label="Scopes" name="scopes">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Scope"
                                        onChange={handleScope}
                                        options={scopes}
                                    />
                                </Form.Item>
                                <Form.Item label="Type" name="type_id">
                                    <Select placeholder="Select Type">
                                        {
                                            types.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Priority" name="priority_id">
                                    <Select placeholder="Select Priority">
                                        {
                                            priorities.map((el, key) => (
                                                <Option key={key} value={el.id}>{el.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Locations" name="locations">
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select Location"
                                        onChange={handleLocation}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={locations}
                                    />
                                </Form.Item>

                                <Form.Item label="Status" name="status">
                                    <Select placeholder="Select Status">
                                        <Option value="OPEN">Open</Option>
                                        <Option value="CLOSED">Closed</Option>
                                        <Option value="FREEZED">Freezed</Option>
                                        <Option value="ASSIGNED">Assigned</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Overdue" name="overdue">
                                    <Select placeholder="Select Overdue">
                                        <Option value="ON_TRACK">ON TRACK</Option>
                                        <Option value="OUT_OF_TARGET">OUT OF TARGET</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Requested At" name="requested_at">
                                    <RangePicker />
                                </Form.Item>

                                <Space>
                                    <Form.Item>
                                        <Button type="primary" htmlType='submit'>
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={resetFilter} danger type="primary">
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Drawer>
                        : null
                }
            </Card>
        </div>
    )
}

export default Ticket